<!-- eslint-disable max-lines -->
<template>
    <paper v-loading="btnloading" class="mip-iframe">
        <div class="application-detail"
            v-loading="detailLoading">
            <!-- 标题区域 -->
            <div class="header-box">
                <h4>商机批量创建</h4>
            </div>
                 <!-- 按钮区域 -->
            <div class="detail-toolBar">
                <lots-button type="primary" :disabled="isEdit"
                    @click="handleConfig">确认生成
                </lots-button>
                <div class="tips-text">本页面仅支持项目及商机的初始新建，如需修改，请到销售易页面操作</div>
            </div>
            <!-- 表单区域 -->
            <div class="info-box">
                <collapse-infos
                    class="infos-width"
                    ref="upholdHealder"
                    :detailData="detailDataLocal"
                    :collapse="COLLAPSE"
                    :activeCollapses="activeCollapses"
                    @change="handleInfosChange">
                    <template v-slot:collapse-append="{form, itemSetting}">
                        <div v-if="itemSetting.prop === 'customerName'" class="customer-box">
                            <lots-md-input
                                ref="customerRef"
                                :label="detailDataLocal.customerName"
                                :disabled="_.get(itemSetting, 'disabled')"
                                :config="itemSetting.config"
                                @change="(data) => { getAdvanceValue(data, itemSetting.prop); }"
                            ></lots-md-input>
                            <lots-button type="primary" class="opp-plan" @click="handleDialog">业务规划</lots-button>
                        </div>
                    </template>
                </collapse-infos>
                <div class="infos-customer">
                    <div class="infos-title">{{ detailDataLocal && detailDataLocal.customerName }}</div>
                    <dc-form
                        ref='dcForm'
                        :row="customerDetail"
                        :config="customerCollapse"
                        :col="1"
                        @data-changed="handleInfosChange"
                        labelWidth="150px">
                        <template v-slot:form-append="{form, itemSetting, rIndex}">
                            <el-form-item
                                :label="itemSetting.label"
                                :prop="itemSetting.prop"
                                label-width="120px">
                                <span class="custom-span" :title="form[itemSetting.prop]">
                                    {{ customerDetail[itemSetting.prop] }}
                                </span>
                            </el-form-item>
                        </template>
                    </dc-form>
                </div>
            </div>
            <!-- 表单区域 -->
            <div class="info-box">
                <collapse-infos
                    class="infos-width"
                    :detailData="detailDataLocal"
                    :collapse="oppCollapse"
                    :activeCollapses="activeCollapses"
                    @change="handleInfosChange">
                    <template v-slot:collapse-append="{form, itemSetting}">
                    <div  class="link-box">
                        <edit-table-box
                            ref="editTableRef"
                            v-loading="subTabTable.tableLoading"
                            :columns="TABLECONFIG.columns"
                            :index="TABLECONFIG.index"
                            :height="tableHeight"
                            :control="!projectData.projectAddress"
                            :allowRowClick="!projectData.projectAddress"
                            :selection="TABLECONFIG.selection"
                            :tableData="subTabTable.totalData"
                            :actions="TABLECONFIG.actions"
                            @element-change="handleChangeRow"
                            @btn-event="actionClick"
                            @row-click="rowClick"
                            >
                            <template #col-header="score">
                                <span >{{ score.item.label }}</span>
                               <el-popover
                                placement="bottom"
                                :trigger="score.item.content ? 'hover' : 'click'"
                                :title="score.item.content ? score.item.content : score.item.label"
                                :min-width="200"
                                >

                               <el-input v-if="score.item.type === 'input'" v-model="searchHeaderParams[score.item.prop]"
                                    @blur="selectPropChange(score.item)"  @keyup.enter.native="selectPropChange()"
                                    :placeholder="score.item.placeholder || `请输入${ score.item.label }`"  clearable @clear="selectPropChange()"></el-input>
                                <i slot="reference" v-else-if="score.item.content" class="el-icon-info tips-color"  />
                                <lots-select
                                    v-else-if="score.item.type === 'select'"
                                    :optionsKey="score.item.optionsParams"
                                    :placeholder="score.item.placeholder || `请选择${ score.item.label }`"
                                    v-model="searchHeaderParams[score.item.prop]"
                                    :multiple="score.item.multiple"
                                    @change="(data) => { selectPropChange(data,score.item) }"
                                    clearable
                                    style="width: 100%" />
                                <i slot="reference" v-if="selectProp.includes(score.item.prop)"  @click.stop="changeVisible()"
                                class="opp-icon iconfont icon-home_btn_new"></i>
                        </el-popover>
                            </template>
                            <template #edit-col-append="{ item, row, rIndex }">
                              <el-input
                                v-if="item.prop==='latestProgress'"
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="row.latestProgress">
                                </el-input>
                              <el-input
                                v-else-if="item.prop==='whRequire'"
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="row.whRequire">
                                </el-input>
                            </template>
                            <template #col-append="{ item, row, rIndex }">
                                <div v-if="['businessType','whType','businessCategory'].includes(item.prop)">
                                    {{setFormatData(item, row)}}
                                </div>
                                 <div v-else-if="['latestProgress','whRequire'].includes(item.prop)">
                                      {{ row[item.prop] }}
                                 </div>
                                <div v-else-if="item.prop === 'statusClass'" :class="[setRowBgColor(row),'status-color']"></div>
                            </template>
                        </edit-table-box>
                        <p class="bottom-tips"><span class="status-color error-status-color"></span> 红色状态：销售易存在”客户+分公司+窗口期“重复的商机，请联系客户开发人进行新增；</p>
                        <p class="bottom-tips"><span class="status-color tips-status-color"></span> 橙色状态：销售易存在”客户+分公司+窗口期“重复的商机，请谨慎操作；</p>
                        </div>
                    </template>
                </collapse-infos>
            <div id="closeMainVisible"></div>
            </div>
            <BusinessDialog ref="BusinessDialog" :detailDataLocal="detailDataLocal" @confirm="confirmHandle"></BusinessDialog>
        </div>
        <el-dialog
            title="提示"
            :visible.sync="projectVisible"
            width="30%"
            >
            <span class="dialog-tips">已生成：<a :href="projectData.projectAddress" target="_blank" rel="noopener noreferrer">{{ projectData.projectCode }}</a></span>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="projectVisible = false">确 定</el-button></span>
        </el-dialog>
    </paper>
</template>

<script>
import { ref, computed, onMounted, reactive, toRefs, getCurrentInstance } from '@vue/composition-api';
import paper from '@/components/core/Paper.vue';
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import { checkAccount, checkCompany, batchOppSave } from '@mdm/api/opportunityModules/opportunityBatch.js';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import { Message, MessageBox } from 'element-ui';
import dcForm from '@/modules/mdm/components/form/Index.vue';
import store from '@/store/index';
import { collapse, oppCollapse, customerCollapse, tableConfig, neocrmBusinessRelation } from './collapseInfosConfig.js';
import _ from 'lodash';
import utils from '@/utils/common';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsMdInput from '@/components/lots/lotsMdInput';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import BusinessDialog from './businessPlan/Index.vue';
const sliceNum = -2; // 获取年后2位，裁剪

const selectProp = ['whType', 'businessCategory', 'systemDocking'];
export default {
    name: 'resourceApplication',
    components: { paper, lotsButton, CollapseInfos, dcForm, editTableBox, lotsSelect, lotsMdInput, BusinessDialog },
    /* eslint-disable max-lines-per-function */
    setup (props, setupContext) {
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(collapse);
        const detailLoading = ref(false);
        const tableHeight = ref('500');
        const btnloading = ref(false);
        const code = ref('');
        const state = reactive({
            searchHeaderParams: {
            },
            showImportLoading: false,
            _
        });
        const detailDataLocal = ref({
        });

        const activeCollapses = ref(['dockingInfo', 'basicInfo']);
        const customerType = '双非客户'; // 双非客户
        const mipUrl = ref('');
        const COLLAPSE = computed(() => { // 配置项动态计算
            const {
                customerClassify
            } = customerDetail.value;
            const { projectAddress } = projectData.value;
            config.forEach((v, i) => {
                // eslint-disable-next-line complexity
                v.formList.forEach((v2, i2) => {
                    if (v2.prop === 'propertyRight') { // 当客户类型为双非客户时，自动带出客户与货物的货权关系为双非业务，不允许修改；
                        v2.disabled = customerClassify === customerType;
                    }

                    if (projectAddress) {
                        v2.disabled = true;
                    }
                });
            });
            return config;
        });
        const TABLECONFIG = computed(() => {
            return tableConfig;
        });

        const { userCode } = store.getters.user;
        class DataDetailClass extends AdvanceListClass {

        }
        // 根据页面类型来获取对应的接口
        const subTabTable = new DataDetailClass({
            Api: {

            }
        });
        // eslint-disable-next-line complexity
        const isEdit = ref(true);
        const _initData = async (loading = true) => {
            loading && (detailLoading.value = true);
            detailLoading.value = false;
            detailDataLocal.value.projectName = utils.formatYear(new Date()).slice(sliceNum) + '年-xxxx项目';
            setUpdateForm();
        };
        // 检查加载用户是否存在权限新增商机创建
        // 商机批量创建页面加载校验接口（页面打开时调用）
        // 成功0
        // 成功200，弹出提示
        // 失败1，报错
        const resTips = '200';
        const handleInfosChange = (formObjData = {}) => { // 表单组件编辑回调，更新本地数据
            detailDataLocal.value = Object.assign({}, detailDataLocal.value, formObjData.form);
            // detailDataLocal.value.projectName = utils.formatYear(new Date()).slice(sliceNum) + '年-xxxx项目';
            if (['window', 'biddingType', 'opportunitySource', 'industryLed'].includes(formObjData.itemSetting.prop)) {
                subTabTable.totalData.value = subTabTable.totalData.value.map((it, index) => {
                    ctx.$set(subTabTable.totalData.value[index], formObjData.itemSetting.prop, formObjData.data);
                    return it;
                });
            }
        };

        const setUpdateForm = () => {
            setupContext.refs.upholdHealder.updataInitData(detailDataLocal.value);
        };
        const edit = -3; // 非编辑状态
        const propertyRightType = '3'; // 货物的货权关系为双非业务
        const customerDetail = ref({
        });
        // 判断已选择的客户是否正常，并获取右边客户信息数据
        const getCheckCustomerAuth = (data, isForm = false, index = -1) => {
            if (!data) {
                customerDetail.value = {};
                return;
            }
            return checkAccount({
                userCode,
                customerCodes: data.customerCode
            }).then(res => {
                if (isForm) {
                    setFormCustomerHandle(res, data);
                } else {
                    setTableCustomerHandle(res, index);
                }
            });
        };
        // 基础信息表单校验
        const setFormCustomerHandle = (res, data) => {
            if (res && (+res.code === 0 || +res.code === +resTips)) {
                if (+res.data[0].code === 0 || +res.data[0].code === +resTips) {
                    customerDetail.value = res.data[0].data;
                    customerDetail.value.code = res.data[0].code;
                    Object.assign(detailDataLocal.value, data || {});
                    detailDataLocal.value.propertyRight = '';
                    if (res.data[0].data.customerClassify === customerType) {
                        detailDataLocal.value.propertyRight = propertyRightType;
                    }
                    if (+res.data[0].code === +resTips) {
                        Message.warning('中风险客户需走准入特批！');
                    }
                } else {
                    customerDetail.value = {};
                    detailDataLocal.value.customerCode = '';
                    detailDataLocal.value.customerName = '';
                    setupContext.refs.customerRef.value = '';
                    Message.error(res.data[0].msg);
                }
            } else {
                customerDetail.value = {};
                detailDataLocal.value.customerCode = '';
                detailDataLocal.value.customerName = '';
                setupContext.refs.customerRef.value = '';
            }
            setUpdateForm();
        };
        // 表格客户校验
        const setTableCustomerHandle = (res, index) => {
            if (res && (+res.code === 0 || +res.code === +resTips)) {
                if (+res.data[0].code === 0 || +res.data[0].code === +resTips) {
                    if (res.data[0].data.customerClassify === customerType) {
                        detailDataLocal.value.propertyRight = propertyRightType;
                    }
                    if (+res.data[0].code === +resTips) {
                        Message.warning('中风险客户需走准入特批！');
                    }
                } else {
                    subTabTable.totalData.value[index].customerCode = '';
                    subTabTable.totalData.value[index].customerName = '';
                    Message.error(res.data[0].msg);
                    return;
                }

                if (index !== -1) {
                    // 获取客户数据，用于参数查询
                    Object.assign(subTabTable.totalData.value[index], res.data[0].data);
                }
            }
            subTabTable.totalData.value[index].code = res.data[0].code;
        };
        // 判断业务类别是否互斥
        const setBusinessRelation = () => {
            const tipsList = [];
            subTabTable.totalData.value.forEach((it, index) => {
                /**
                 * 遍历当前表格数据
                 * 遍历当前业务类别，获取第一个值的业务类别，和当前业务类别的交集，存在交集且交集在第一个值的业务类别返回内就正常，否则报错
                 *
                 * */
                if (!it.businessCategory) return;
                const currentBusinessCategory = _.cloneDeep(it.businessCategory)?.map(it => Number(it)) || [];
                currentBusinessCategory.forEach(ele => {
                    const isError = _.difference(currentBusinessCategory, neocrmBusinessRelation[ele]).length === 0; // 判断当前索引的可选业务类型是否不包含当前已选择的业务类别
                    if (!isError) {
                        !tipsList.includes(index + 1) && tipsList.push(index + 1);
                    }
                });
            });
            let tips = '';
            if (tipsList.length) {
                const tipsText = tipsList.join(',');
                tips = `商机第${tipsText}行业务类别存在互斥`;
            }
            return tips;
        };
        const projectData = ref({
        });
        const projectVisible = ref(false);
        const handleConfig = () => {
            const data = _.cloneDeep(setupContext.refs.upholdHealder.onsave());
            if (!data) return;
            const projectName = detailDataLocal.value.projectName.toUpperCase();
            if (projectName.indexOf('X') > -1) {
                return Message.warning('项目名称不能包含XXX');
            }
            const isErrorItem = subTabTable.totalData.value.some(it => it.isSelectDisbled);
            if (isErrorItem) { // 商机行不能有标红的数，否则报错
                return Message.warning('仅支持批量新增商机，暂不支持批量编辑商机，请核对！');
            }
            const isBusiness = setBusinessRelation();
            if (isBusiness) {
                return Message.warning(isBusiness);
            }
            const fullOpportunitiesCode = subTabTable.totalData.value.some(it => it.fullOpportunitiesCode);
            const allHaveId = _.every(subTabTable.totalData.value, _.property('fullOpportunitiesCode'));
            if (fullOpportunitiesCode && !allHaveId) { // 只要存在一条商机行关联业务规划，校验所有商机行都关联业务规划，否则报错
                return Message.warning('存在商机行未关联业务规划，请核实！');
            }
            let index = 0;
            const ModifyData = subTabTable.totalData.value.some((row, idx) => {
                const currentStatus = !setupContext.refs.editTableRef.saveItemData(row);
                index = idx;
                return currentStatus;
            });
            if (ModifyData) {
                subTabTable.totalData.value = subTabTable.totalData.value.map((it, inx) => {
                    if (index === inx) {
                        it.edit = 3;
                    } else {
                        it.edit = -2;
                    }
                    return it;
                });
                return;
            }
            const params = {
                userCode,
                accountId: detailDataLocal.value.crmCustomerId,
                window: detailDataLocal.value.window,
                bigBusinessType: detailDataLocal.value.bigBusinessType,
                product: detailDataLocal.value.product,
                propertyRight: detailDataLocal.value.propertyRight,
                projectName: detailDataLocal.value.projectName,
                oppList: subTabTable.totalData.value.map(it => {
                    return {
                        accountId: it.crmCustomerId,
                        bidScale: it.bidScale,
                        companyId: it.company,
                        businessCenter: it.businessCenterCode,
                        opportunityName: it.opportunityName,
                        window: it.window,
                        businessType: it.businessType,
                        whType: it.whType,
                        businessCategory: it.businessCategory.map(it => Number(it)),
                        opportunityType: it.opportunityType,
                        money: it.money,
                        prefectureLevelCity: it.prefectureLevelCity,
                        accountPeriod: it.accountPeriod,
                        bidDeposit: it.bidDeposit,
                        operateDeposit: it.operateDeposit,
                        latestProgress: it.latestProgress,
                        whArea: it.whArea,
                        whRequire: it.whRequire,
                        isPureWarehouse: it.isPureWarehouse,
                        dayOrder: it.dayOrder,
                        averagePrice: it.averagePrice,
                        systemDocking: it.systemDocking,
                        industryLed: it.industryLed,
                        biddingType: it.biddingType,
                        currentSupplier: it.currentSupplier,
                        promiseSigningPlan: it.promiseSigningPlan,
                        cooperateScale: it.cooperateScale,
                        opportunitySource: it.opportunitySource,
                        fullOpportunitiesId: it.fullOpportunitiesId
                    };
                })
            };
            MessageBox.confirm('仅支持批量新增商机，暂不支持批量编辑商机，请核对！', '提示', {
                confirmButtonText: '确定生成',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        batchOppSave(params).then(res => {
                            if (res && +res.code === 0) {
                                projectData.value = res.data;
                                Message.success({ message: '生成成功', customClass: 'mess-tips' });
                                projectVisible.value = true;
                            }
                        }).finally(() => {
                            instance.confirmButtonLoading = false;
                            done();
                        });
                    } else {
                        done();
                    }
                }
            });
        };
        const handleDialog = () => {
            if (!detailDataLocal.value.customerCode || !detailDataLocal.value.window) {
                return Message.warning('请先维护客户与窗口期信息！');
            }
            if (projectData.value.projectAddress) {
                return Message.warning('当前状态不可用');
            }
            setupContext.refs.BusinessDialog.show();
        };
        const actionClick = (row, event, index) => {
            if (event === 'saveItem') {
                const ModifyData = setupContext.refs.editTableRef.saveItemData(row);
                if (ModifyData) {
                    subTabTable.totalData.value[row.serialNum].edit = -3;
                }
            } else if (event === 'edit') {
                setTableInitConfig(row);
                subTabTable.totalData.value = subTabTable.totalData.value.map((it, index) => {
                    if (+row.serialNum === +it.serialNum) {
                        subTabTable.totalData.value[index].edit = 2;
                    } else {
                        subTabTable.totalData.value[index].edit = -3;
                    }
                    return it;
                });
            } else if (event === 'copy') {
                subTabTable.totalData.value.splice(1, 0, _.cloneDeep(subTabTable.totalData.value[row.serialNum]));
            } else if (event === 'del') {
                /**
                 * 点击删除，删除该行数据，并刷新主档的分公司勾选的数据；
                    当其他行存在与被删除行相同的分公司时，系统不取消勾选主档的分公司字段；
                    当其他行不存在与被删除行相同的分公司时，系统自动取消勾选主档的分公司字段；
                */
                subTabTable.totalData.value.splice(row.serialNum, 1);
            }
        };
        const handleChangeRow = async ({ prop, val, row, index }) => {
            switch (prop) {
            case 'customerName':
                subTabTable.totalData.value[index].businessType = '';
                subTabTable.totalData.value[index].whType = '';
                subTabTable.totalData.value[index].businessCategory = '';
                await getCheckCustomerAuth(val, false, index);

                setTableInitConfig(row);
                break;
            case 'businessType':
                subTabTable.totalData.value[index].businessCategory = '';
                subTabTable.totalData.value[index].whType = '';
                setTableInitConfig(row);
                // tableConfig.columns.forEach(it => {
                //     if (it.prop === 'whType') {
                //         it.disabled = !val; // 当商机大类未填写时，始发仓、业务类别置灰
                //         const optionsItem2 = dictData.value.NEOCRM_BUSINESS_WH[row.businessType];
                //         it.options = {};
                //         _.split(optionsItem2, ',').forEach(el => {
                //             it.options[el] = dictData.value.NEOCRM_WH_TYPE[el];
                //         });
                //     } else if (it.prop === 'businessCategory') {
                //         it.disabled = !val; // 当商机大类未填写时，始发仓、业务类别置灰
                //         const optionsItem3 = dictData.value.NEOCRM_OPP_BUSINESS[row.businessType];
                //         it.options = {};
                //         _.split(optionsItem3, ',').forEach(el => {
                //             it.options[el] = dictData.value.SYS_BMS_BUSI_TYPE[el];
                //         });
                //     }
                // });
                break;
            case 'businessCategory':
                tableConfig.columns.forEach(it => {
                    if (it.prop === 'whArea') {
                        //  业务类别包含【仓储】或【快递】时必填
                        it.mustFill = _.intersection(row.businessCategory, ['1', '5']).length;
                    } else if (it.prop === 'whRequire') {
                        // 业务类别包含【仓储】时必填
                        it.mustFill = _.intersection(row.businessCategory, ['1']).length;
                    } else if (it.prop === 'isPureWarehouse') {
                        // 是否裸租仓, 业务类别包含【仓储】时必填
                        // 2、当商机大类为基地仓/快消仓配/小件电商/大件2C时，裸租仓默认为否且只读；
                        //  当商机大类为大件仓配/其他时，清空裸租仓字段，允许编辑；
                        it.mustFill = _.intersection(row.businessCategory, ['1']).length;
                        tableConfig.columns.forEach(it => {
                            if (it.prop === 'isPureWarehouse') {
                                const isAbled = ['1', '3', '5', '6'].includes(row.businessType);
                                subTabTable.totalData.value[row.serialNum].isPureWarehouse = isAbled ? '2' : ''; // 2:否
                                it.disabled = isAbled;
                            }
                        });
                    } else if (['SYSTEM_DOCKING', 'dayOrder', 'averagePrice'].includes(it.prop)) {
                        // 日均单量/每单均价/系统对接, 业务类别包含【快递】时必填
                        it.mustFill = _.intersection(row.businessCategory, ['5']).length;
                    }
                });
                break;
            case 'window':
                getCheckCompanyTable(row, index);
                break;
            }
        };
        const getCheckCompanyTable = (row, index) => {
            const params = [{
                userCode,
                customerId: row.crmCustomerId,
                companyId: row.company,
                window: row.window
            }];
            checkCompany({
                list: params
            }).then(res => {
                /* eslint-disable max-nested-callbacks */
                if (res && +res.code === 0) {
                    if (Array.isArray(res.data) && res.data.length) { subTabTable.totalData.value[index].companyStatusCode = res.data[0].code; }
                }
            });
        };
        const rowClick = (row) => {
            setTableInitConfig(row);
        };
        const setTableInitConfig = (row) => {
            tableConfig.columns.forEach(it => {
                if (it.prop === 'businessType') {
                    const optionsItem = dictData.value.NEOCRM_INDUSTRY_BUSINESS[row.industryCode];
                    it.options = {};
                    _.split(optionsItem, ',').forEach(el => {
                        it.options[el] = dictData.value.NEOCRM_BUSINESS_TYPE[el];
                    });
                } else if (it.prop === 'whType') {
                    const optionsItem2 = dictData.value.NEOCRM_BUSINESS_WH[row.businessType] || '';
                    it.options = {};
                    _.split(optionsItem2, ',').forEach(el => {
                        it.options[el] = dictData.value.NEOCRM_WH_TYPE[el];
                    });
                    if (row.businessType) {
                        it.disabled = false;
                    } else {
                        it.disabled = true;
                    }
                } else if (it.prop === 'businessCategory') {
                    const optionsItem3 = dictData.value.NEOCRM_OPP_BUSINESS[row.businessType] || '';

                    it.options = {};
                    _.split(optionsItem3, ',').forEach(el => {
                        it.options[el] = dictData.value.SYS_BMS_BUSI_TYPE[el];
                    });
                    if (row.businessType) {
                        it.disabled = false;
                    } else {
                        it.disabled = true;
                    }
                } else if (['customerName', 'prefectureLevelCity'].includes(it.prop)) {
                    it.disabled = !!row?.fullOpportunitiesCode;
                }
            });
        };
        const propDictList = {
            businessType: {
                dict: 'NEOCRM_INDUSTRY_BUSINESS',
                prop: 'industryCode'
            },
            whType: {
                dict: 'NEOCRM_OPP_BUSINESS',
                prop: 'businessType'
            },
            businessCategory: {
                dict: 'NEOCRM_BUSINESS_EXCLUSIVE',
                prop: 'industryCode'
            }
        };
        const selectPropChange = (data, col) => {
            if (!data) return;
            MessageBox.confirm('是否批量覆盖空白行？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let isTips = false;
                subTabTable.totalData.value = _.cloneDeep(subTabTable.totalData.value.map((it, index) => {
                    const optionsItem = dictData.value[propDictList[col.prop].dict][it[propDictList[col.prop].prop]];
                    const currentlist = _.split(optionsItem, ',');
                    if (currentlist.includes(data) && !it[col.prop]) {
                        it[col.prop] = data;
                    } else if (!currentlist.includes(data)) {
                        isTips = true;
                    }
                    return it;
                }));
                if (isTips) {
                    Message.warning(`${col.label}不符合覆盖条件，请手动选择`);
                }
                setTableInitConfig(subTabTable.totalData.value.find(it => +it.edit === 2));
            }).catch(() => { });
        };
        // const setTableDisAble = (row) => {
        //     if (!row) return;
        //     tableConfig.columns.forEach(it => {
        //         if (it.prop === 'businessType') {
        //             const optionsItem = dictData.value.NEOCRM_INDUSTRY_BUSINESS[row.industryCode];
        //             it.options = {};
        //             _.split(optionsItem, ',').forEach(el => {
        //                 it.options[el] = dictData.value.NEOCRM_BUSINESS_TYPE[el];
        //             });
        //         } else if (it.prop === 'whType') {
        //             const optionsItem2 = dictData.value.NEOCRM_BUSINESS_WH[row.businessType];
        //             it.options = {};
        //             _.split(optionsItem2, ',').forEach(el => {
        //                 it.options[el] = dictData.value.NEOCRM_WH_TYPE[el];
        //             });
        //             it.disabled = false;
        //         } else if (it.prop === 'businessCategory') {
        //             const optionsItem3 = dictData.value.NEOCRM_BUSINESS_EXCLUSIVE[row.businessType];
        //             it.options = {};
        //             _.split(optionsItem3, ',').forEach(el => {
        //                 it.options[el] = dictData.value.SYS_BMS_BUSI_TYPE[el];
        //             });
        //             it.disabled = false;
        //         } else if (['customerName', 'prefectureLevelCity'].includes(it.prop)) {
        //             it.disabled = !!row.fullOpportunitiesCode;
        //         }
        //     });
        // };
        const changeVisible = () => {
            document.getElementById('closeMainVisible').click(); // doClose失效 ,Visible 绑定无法更新到视图 用于关闭其他el-popover弹窗
        };
        const dictData = ref({});
        const getDictData = async () => {
            const dictCodes = ['NEOCRM_INDUSTRY_BUSINESS', 'NEOCRM_BUSINESS_TYPE', 'NEOCRM_BUSINESS_EXCLUSIVE', 'NEOCRM_BUSINESS_WH', 'NEOCRM_WH_TYPE', 'NEOCRM_OPP_BUSINESS', 'SYS_BMS_BUSI_TYPE'];
            const res = await getDictDataApi({ dictCodes: String(dictCodes) });
            dictData.value = res.data;
        };
        const getAdvanceValue = (data) => {
            if (!data.customerCode) {
                customerDetail.value = {};
                subTabTable.totalData.value = [];
                detailDataLocal.value.customerName = '';
                detailDataLocal.value.customerCode = '';
                detailDataLocal.value.customerGroupName = '';
                detailDataLocal.value.crmCustomerId = '';
                detailDataLocal.value.customerGroupName = '';
                detailDataLocal.value.propertyRight = '';
                setUpdateForm();
                return;
            };

            setupContext.root.$nextTick(() => {
                setupContext.refs.upholdHealder.$refs.dcForm[0].$refs.basicForm.clearValidate(['customerName']);
            });

            if (data.customerCode === detailDataLocal.value.customerCode) { return setUpdateForm(); }
            subTabTable.totalData.value = [];
            getCheckCustomerAuth(data, true);
            setUpdateForm();
            Object.assign(detailDataLocal.value, data || {});
        };
        const colorList = {
            error: 'error-status-color',
            tips: 'tips-status-color'
        };
        const setRowBgColor = (row) => {
            let bgColor = '';
            if (+row.customerStatusCode === 1 || +row.companyStatusCode === 1) {
                bgColor = colorList.error;
            } else if (+row.companyStatusCode === +resTips) {
                bgColor = colorList.tips;
            }
            return bgColor;
        };
        const setTableItem = (it, { opportunityName }) => {
            return {
                customerName: detailDataLocal.value.customerName,
                company: it.companyId,
                companyName: it.company,
                companyCode: it.companyCode,
                opportunityName: opportunityName,
                allowEdit: true,
                edit: edit,
                id: subTabTable.totalData.value.length + 1,
                code: it.code,
                companyStatusCode: it.companyStatusCode,
                customerStatusCode: it.customerStatusCode,
                isPureWarehouse: '',
                crmCustomerId: it.customerId,
                window: detailDataLocal.value.window,
                industryCode: it.industryCode,
                biddingType: detailDataLocal.value.biddingType,
                industryLed: detailDataLocal.value.industryLed,
                opportunitySource: detailDataLocal.value.opportunitySource,
                fullOpportunitiesCode: it.fullOpportunitiesCode,
                fullOpportunitiesId: it.fullOpportunitiesId,
                prefectureLevelCity: it.prefectureLevelCity,
                whArea: it.whArea,
                whType: '',
                businessType: '',
                businessCategory: '',
                cooperateScale: it.cooperationScale,
                money: it.money,
                promiseSigningPlan: it.promiseSigningPlan,
                currentSupplier: it.currentSupplier,
                latestProgress: '',
                whRequire: ''
            };
        };
        const confirmHandle = (selectedList) => {
            selectedList.forEach((it, index) => {
                const items = subTabTable.totalData.value.some(ele => ele.fullOpportunitiesId === it.fullOpportunitiesId);
                const isCopyBusiness = dictData.value.NEOCRM_INDUSTRY_BUSINESS[+it.industryCode]?.indexOf(it.businessTypeCode);
                const isCopyCategory = dictData.value.NEOCRM_BUSINESS_EXCLUSIVE[+it.businessTypeCode]?.indexOf(it.businessTypeCode);
                const isCopyWhtype = dictData.value.NEOCRM_BUSINESS_WH[+it.businessTypeCode]?.indexOf(it.whTypeCode);
                if (items) return;
                const companyShortName = it.company.replace('分公司', '');
                const oppName = `${utils.formatYear(new Date()).slice(sliceNum)}-${it.customerShortName}-${companyShortName}-`;
                const currentItem = setTableItem(it, { opportunityName: oppName });
                if (isCopyBusiness > -1) {
                    currentItem.businessType = String(it.businessTypeCode);
                    currentItem.businessCategory = isCopyCategory > -1 ? it.businessCategoryCode.map(it => String(it)) : '';
                    currentItem.whType = isCopyWhtype > -1 ? it.whTypeCode : '';
                }
                subTabTable.totalData.value.push(_.cloneDeep(currentItem));
            });
            tableConfig.columns.forEach(it => {
                if (it.prop === 'customerName') {
                    it.disabled = true;
                }
                if (it.prop === 'prefectureLevelCity') {
                    it.disabled = true;
                }
            });
            isEdit.value = !subTabTable.totalData.value.length;
        };
        const setFormatData = (item, row) => {
            if (item.prop === 'businessType') {
                return dictData.value.NEOCRM_BUSINESS_TYPE[row[item.prop]];
            } else if (item.prop === 'whType') {
                return dictData.value.NEOCRM_WH_TYPE[row[item.prop]];
            } else if (item.prop === 'businessCategory') {
                const currentList = Array.isArray(row[item.prop]) ? row[item.prop] : [];
                return currentList.map(it => {
                    return dictData.value.SYS_BMS_BUSI_TYPE[it];
                }).join(',');
            }
        };

        onMounted(async () => {
            await getDictData();
            _initData();
        });
        return {
            ...toRefs(state),
            config,
            COLLAPSE,
            customerCollapse,
            detailLoading,
            btnloading,
            code,
            detailDataLocal,
            mipUrl,
            handleInfosChange,
            activeCollapses,
            handleConfig,
            customerDetail,
            subTabTable,
            TABLECONFIG,
            tableHeight,
            oppCollapse,
            handleChangeRow,
            selectProp,
            selectPropChange,
            changeVisible,
            isEdit,
            actionClick,
            getAdvanceValue,
            handleDialog,
            rowClick,
            confirmHandle,
            setFormatData,
            setRowBgColor,
            projectVisible,
            projectData
        };
    }
};
</script>
<style lang="less" scoped>
.info-box{
    display: flex;
    flex-direction: row;
}
.infos-customer{
    width: 300px;
    border-radius: 16px;
    box-shadow: 0 0 5px 6px rgba(0, 0, 0, .1);
    margin-left: 50px;
    height: 100%;
    padding: 20px 0;
    .infos-title{
        text-align: center;
        color: #4285F5;
        font-weight: 600;
        font-size: 16px;
        padding: 0px 0px 10px 0px;
    }
}
.opp-icon{
    font-size: 12px;
    margin-left: 6px;
    cursor: pointer;
}
.customer-box{
    display: flex;
    .opp-plan{
        width: 60px;
        margin-left: 10px;
    }
}
.status-color{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;

}
.tips-status-color{
   background-color: #f78900 !important
}
.error-status-color{
   background-color: #f44336 !important
}
.tips-text{
    color: #f44336;
    margin-top: 6px;
}
.bottom-tips{
    font-size: 10px;
    color: #808799;
    margin: 5px 0px 0px 0px;
    line-height: 16px;
}
.dialog-tips{
    padding: 20px 20px 44px;
    display: inline-block;
}
</style>
<style lang="less">
@scrollbarThumbColor: rgba(0,0,0, .2);
@scrollbarThumbColorHover: rgba(0,0,0, .4);
.infos-customer .form-box .el-form-item {
    margin-bottom: 0px!important;
}
.mip-iframe {
    overflow: auto;
    .application-detail {
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
            }
            .header-info {
                width: 100%;
                display: flex;
                font-size: 14px;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 5px;
                .tips {
                    flex: 1;
                    color: red;
                }
                .apply-info {
                    line-height: 20px;
                    color: #606266;
                }
            }
        }
        .link-box{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .flow-link{
            color: #409eff;
        }
        .el-collapse-item__content {
            padding-bottom: 0;
        }
        .el-collapse-item__arrow {
            margin: 0 8px 0 5px;
        }
        .el-button.is-disabled.is-plain,
        .el-button.is-disabled.is-plain:focus,
        .el-button.is-disabled.is-plain:hover {
            border-color: #FFF;
        }
    }
    .ifr {
        width: 100%;
        height: 100vh;
        border: none;
    }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }

    .header-operation {
        margin-top: 10px;
    }
}
.infos-width{
    width: 100%;
}
.mess-tips{
    z-index: 3999!important;
}
</style>
