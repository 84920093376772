export const config = {
    name: 'businessPlan',
    searchFields: [
        {
            name: '客户系',
            value: 'customerGroup',
            isFixed: true,
            type: 'input',
            disabled: true,
            span: 3
        },
        {
            name: '客户名称',
            value: 'customerName',
            width: '160',
            type: 'advance',
            span: 3,
            isFixed: true,
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' }
            ],
            tableConfig: [
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerName', value: '客户名称', displayName: true },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            prefix: '/api-lcrm',
            method: 'post',
            advanceUrl: '/customer/info/page',
            cbParams: ['crmCustomerId#accountId', 'customerName#customerName', 'customerShortName', 'customerClassify'],
            advanceCascade: 'customerGroup',
            beforeRequest: (params) => {
                // params.customerGroupName = params.customerGroup;
                // delete params.customerGroup;
                return params;
            }
        },
        {
            name: '分公司',
            value: 'companyName',
            type: 'advance',
            span: 3,
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称', displayName: true }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: ['companyCode#companyId', 'companyNameCn#companyName'],
            isFixed: true
        },
        {
            name: '地级市',
            value: 'prefectureLevelCity',
            type: 'advance',
            span: 3,
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '城市名称' },
                { 'prop': 'code', 'value': '城市编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '城市编码' },
                { 'prop': 'nameCn', 'value': '城市名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['code', 'nameCn#prefectureLevelCity'],
            beforeRequest: (params) => {
                params.type = 'PLACE_CITY';
                params.countryCode = 100000;
                return params;
            },
            isFixed: true
        }

    ],
    selection: true,
    index: false,
    tableData: {
        columns: [
            {
                label: '状态',
                type: 'text',
                prop: 'statusClass'
            },
            {
                label: '客户行业',
                minWidth: 100,
                prop: 'industryCategories'
            },
            {
                label: '客户系',
                prop: 'customerGroup',
                minWidth: 120
            },
            {
                label: '客户等级',
                prop: 'customerLevel'
            },
            {
                label: '客户',
                prop: 'customerName',
                headerType: 'advance',
                sort: true,
                params: {
                    params: {
                        label: '客户名称',
                        name: '客户名称',
                        prop: 'customerName',
                        value: 'customerName',
                        width: '160',
                        type: 'advance',
                        mustFill: true,
                        advanceConfig: [
                            { prop: 'customerName', value: '客户名称' },
                            { prop: 'customerCode', value: '客户编码' }
                        ],
                        tableConfig: [
                            { prop: 'customerCode', value: '客户编码' },
                            { prop: 'customerName', value: '客户名称', displayName: true }
                        ],
                        prefix: '/api-lcrm',
                        method: 'post',
                        advanceUrl: '/customer/info/page',
                        cbParams: ['crmCustomerId#accountId', 'customerName#customerName', 'customerShortName', 'customerClassify'],
                        advanceCode: 'advanceCode',
                        beforeRequest: (params) => {
                            // params.customerStatus = '1';
                            return params;
                        }
                    }
                },
                propLike: 'accountId',
                minWidth: 160
            },
            {
                label: '客户物流规模(万/年)',
                prop: 'logisticsScale',
                minWidth: 150
            },
            {
                label: '地级市',
                prop: 'prefectureLevelCity',
                headerType: 'advance',
                sort: true,
                params: {
                    params: {
                        name: '地级市',
                        label: '地级市',
                        value: 'prefectureLevelCity',
                        type: 'advance',
                        span: 3,
                        advanceConfig: [
                            { 'prop': 'nameCn', 'value': '城市名称' },
                            { 'prop': 'code', 'value': '城市编码' }
                        ],
                        tableConfig: [
                            { 'prop': 'code', 'value': '城市编码' },
                            { 'prop': 'nameCn', 'value': '城市名称', displayName: true }
                        ],
                        prefix: '/api-lcrm',
                        method: 'get',
                        advanceUrl: `/place/selectPage`,
                        cbParams: ['nameCn#prefectureLevelCity'],
                        beforeRequest: (params) => {
                            params.type = 'PLACE_CITY';
                            params.countryCode = 100000;
                            return params;
                        }
                    }
                },
                propLike: 'prefectureLevelCity',
                minWidth: 100
            },
            {
                label: '分公司',
                prop: 'company',
                sort: true,
                headerType: 'advance',
                params: {
                    params: {
                        name: '分公司',
                        value: 'company',
                        type: 'advance',
                        span: 4,
                        advanceConfig: [
                            { 'prop': 'companyNameCn', 'value': '分公司名称' },
                            { 'prop': 'companyCode', 'value': '分公司编码' }
                        ],
                        tableConfig: [
                            { 'prop': 'companyCode', 'value': '分公司编码' },
                            { 'prop': 'companyNameCn', 'value': '分公司名称' }
                        ],
                        prefix: '/api-mdm',
                        advanceUrl: `/esCompany/popCompany`,
                        cbParams: ['companyCode#companyId', 'companyNameCn#company'],
                        isFixed: true
                    }
                },
                propLike: 'companyId',
                minWidth: 100
            },

            {
                label: '窗口期',
                prop: 'window',
                sort: true,
                headerType: 'date',
                dateType: 'datetimerange',
                startPlaceholder: '窗口期从',
                endPlaceholder: '窗口期到',
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                propLike: 'window',
                minWidth: 120
            },
            {
                label: '商机大类',
                headerType: 'select',
                sort: true,
                params: {
                    params: {
                        optionsKey: 'NEOCRM_BUSINESS_TYPE'
                    }
                },
                propLike: 'businessType',
                optionsKey: 'NEOCRM_BUSINESS_TYPE',
                prop: 'businessType',
                minWidth: 120
            },
            {
                label: '始发仓',
                prop: 'whType',
                sort: true,
                headerType: 'select',
                params: {
                    params: {
                        optionsKey: 'NEOCRM_WH_TYPE'
                    }
                },
                propLike: 'whType',
                minWidth: 100
            },
            {
                label: '业务类别',
                prop: 'businessCategory',
                headerType: 'select',
                params: {
                    params: {
                        optionsKey: 'SYS_BMS_BUSI_TYPE'
                    }
                },
                propLike: 'businessCategory',
                minWidth: 120
            },
            {
                label: '安得已合作规模(万/年)',
                prop: 'cooperationScale',
                minWidth: 100
            },
            {
                label: '签单计划(万/年)',
                prop: 'money',
                minWidth: 100
            },
            {
                label: '承诺签单计划(万/年)',
                prop: 'promiseSigningPlan',
                minWidth: 100
            },
            {
                label: '现供方',
                prop: 'currentSupplier',
                minWidth: 100
            },
            {
                label: '仓库面积',
                prop: 'whStorageArea'
            },
            {
                label: '业务规划单据编码',
                prop: 'fullOpportunitiesCode',
                minWidth: 160
            }
        ]
    }
};
