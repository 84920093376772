import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

//  批量新增商机-校验客户--yjl
export const checkAccount = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/batchOpp/checkAccount',
        method: 'post',
        data
    });
};
// 批量新增商机-操作人是否存在逾期商机，校验是否允许新建商机
export const checkBatchOppLoad = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/batchOpp/load',
        method: 'post',
        data
    });
};
// 批量新增商机-校验分公司
export const checkCompany = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/batchOpp/checkCompany',
        method: 'post',
        data
    });
};
// 批量新增商机-业务规划
export const getFullOpportunities = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/batchOpp/getFullOpportunities',
        method: 'post',
        data
    });
};
// 批量新增商机-保存
export const batchOppSave = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/batchOpp/batchOppSave',
        method: 'post',
        data
    });
};
