<template>
    <div class="company-price-dialog">
        <el-dialog
            :visible="visibleDialog"
            width="70%"
            title="选取客户业务规划"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="visibleDialog = false">
            <div class="change-btn-box">
                <search-box
                    ref="searchBox"
                    :search-key="config.name"
                    :fields="config.searchFields"
                    :showResetBtn="true"
                    @elementChange="elementChange"
                    @search-change="subTabTable.searchList"
                />
                <!-- 表格    -->
                <table-box
                    ref="tableBusRef"
                    height="280"
                    v-loading="subTabTable.tableLoading"
                    :selection="config.selection"
                    :index="config.index"
                    :columns="config.tableData.columns"
                    :rows="subTabTable.totalData"
                    :selectable="selectable"
                    @sort-change="handleSort"
                    @selection-change="handleSelectionChange">
                        <template #col-header="score">
                            <span>{{ score.col.label }}</span>
                            <el-popover
                                :visible="visibleSearch"
                                ref="popover"
                                placement="bottom"
                                trigger="click"
                                title="筛选条件"
                                :min-width="200"
                                >
                                <lots-select
                                    v-if="score.col.headerType === 'select'"
                                    :optionsKey="score.col.params.params.optionsKey||''"
                                    :placeholder="score.col.placeholder || `请选择${ score.col.label }`"
                                    v-model="searchHeaderParams[score.col.prop]"
                                    @change="getheaderParamList()"
                                    clearable
                                    style="width: 100%" />
                                <lots-md-input
                                    v-else-if="score.col.headerType==='advance'"
                                    :label="searchHeaderParams[score.col.prop]"
                                    v-model="searchHeaderParams[score.col.prop]"
                                    :config="score.col.params.params"
                                    :disabled="_.get(score.col, 'disabled', false)"
                                    @change="(data) => { getAdvanceValue(data, score.col); }"
                                ></lots-md-input>
                                <otpAdvanceMulti
                                    v-else-if="score.col.headerType === 'advanceMulti'"
                                    :params="score.col.params.params"
                                    @getAdvanceValue="(data) => { getAdvanceValue(data, score.col); }"
                                    :disabled="_.get(score.col, 'disabled', false)"
                                    :mValue="searchHeaderParams[score.col.prop]"
                                    >
                                </otpAdvanceMulti>
                                <el-date-picker
                                    v-else-if="score.col.headerType==='date'"
                                    v-model="searchHeaderParams[score.col.propLike]"
                                    :type="score.col.dateType || 'daterange'"
                                    :format="score.col.format"
                                    :value-format="score.col.valueFormat"
                                    :range-separator="score.col.separator|| '至'"
                                    :start-placeholder="score.col.startPlaceholder||'开始日期'"
                                    :end-placeholder="score.col.endPlaceholder||'结束日期'"
                                    :default-time="score.col.defaultTime || ['00:00:00', '23:59:59']"
                                    @change="getheaderParamList()"
                                    >
                                </el-date-picker>
                                <el-input v-else-if="score.col.headerType" v-model="searchHeaderParams[score.col.prop]" @blur="getheaderParamList()"
                                    @keyup.enter.native="getheaderParamList()"  :placeholder="score.col.placeholder || `请输入${ score.col.label }`"  clearable @clear="getheaderParamList()"></el-input>
                                <i slot="reference" @click.stop="changeVisible"  v-if="score.col.headerType"
                                :class="[changeIconActive(searchHeaderParams[score.col.prop], score.col.prop), 'price-icon iconfont icon-ad-search']"></i>
                            </el-popover>
                        </template>
                        <template v-slot:col-append="{ col, row }">
                            <div v-if="col.prop === 'statusClass'" :class="[setRowBgColor(row),'status-color']"></div>
                        </template>
                </table-box>
                <lots-pagination
                    :current-page.sync="subTabTable.pageNo"
                    @current-change="pageChange"
                    @size-change="sizeChange"
                    :pageSize="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="subTabTable.pageTotal" >
                </lots-pagination>
                 <p class="bottom-tips"><span class="status-color error-status-color"></span> 红色状态：销售易存在”客户+分公司+窗口期“重复的商机，请联系客户开发人进行新增；</p>
                        <p class="bottom-tips"><span class="status-color tips-status-color"></span> 橙色状态：销售易存在”客户+分公司+窗口期“重复的商机，请谨慎操作；</p>
            </div>
            <span slot="footer" class="text-right">
                <lots-button type="primary" size="mini" :loading="checkLoading" :disabled="checkBtnDisabled"  @click="getAuthList">校验</lots-button>
                <lots-button type="primary" size="mini" :disabled="isCheckAuth" @click="confirm">确认</lots-button>
                <lots-button size="mini" @click="visibleDialog = false">取消</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, toRefs } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import lotsMdInput from '@/components/lots/lotsMdInput';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { getFullOpportunities, checkAccount, checkCompany } from '@mdm/api/opportunityModules/opportunityBatch.js';
import otpAdvanceMulti from '@/components/lots/otpAdvanceMulti/index.vue';
import store from '@/store/index';
import _ from 'lodash';
import { config as tableConfig } from './config.js';
import { Message } from 'element-ui';
export default {
    components: { lotsButton, tableBox, searchBox, lotsPagination, lotsMdInput, lotsSelect, otpAdvanceMulti },
    props: {
        detailDataLocal: {
            type: Object,
            return: () => {}
        }
    },
    /* eslint-disable max-lines-per-function */
    setup (props, setupContext) {
        const { userCode } = store.getters.user;
        const config = reactive(tableConfig);
        const searchHeaderParams = ref({
            prefectureLevelCity: '',
            customerName: '',
            company: ''
        });
        const state = reactive({
            pageSize: 20,
            _
        });
        const visibleDialog = ref(false);
        const visibleSearch = ref(true);
        const emit = setupContext.emit;
        class ContractListClass extends AdvanceListClass {
            beforeGetList (condition) {
                Object.assign(condition, setupContext.refs.searchBox.searchData, searchHeaderParams.value);
                condition.userCode = userCode;
                condition.orderBy = orderBy.value;
                condition.orderByType = orderByType.value;
                condition.pageSize = state.pageSize;
                return condition;
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const subTabTable = new ContractListClass({
            Api: {
                listApi: getFullOpportunities // 查询接口
            },
            pageSize: 20
        });
        const confirm = () => {
            emit('confirm', selectedList.value);
            visibleDialog.value = false;
        };
        const show = () => {
            checkLoading.value = false;
            visibleDialog.value = true;
            checkBtnDisabled.value = false;
            setupContext.root.$nextTick(() => {
                setupContext.refs.searchBox.initSearchData();
                const params = {
                    customerGroup: props.detailDataLocal.customerGroupName || '',
                    customerName: props.detailDataLocal.customerName || '',
                    accountId: props.detailDataLocal.crmCustomerId
                };
                setupContext.refs.searchBox.setQueryParams(params);
                subTabTable.getList(params);
            });
            searchHeaderParams.value = {};
        };
        const selectedList = ref([]);
        const handleSelectionChange = (data) => {
            selectedList.value = data;
            isCheckAuth.value = true;
            checkBtnDisabled.value = false;
        };
        const getheaderParamList = () => {
            const searchHeaderParam = _.cloneDeep(searchHeaderParams.value);
            if (searchHeaderParam.window) {
                searchHeaderParam.startWindow = searchHeaderParam.window?.[0] || null;
                searchHeaderParam.endWindow = searchHeaderParam.window?.[1] || null;
                Reflect.deleteProperty(searchHeaderParam, 'window');
            }
            subTabTable.getList(searchHeaderParam);
            // changeVisible();
        };
        const getAdvanceValue = (data, item) => {
            if (item.prop === 'company') {
                data.companyName = data[item.prop];
                // delete data[item.prop];
            }

            searchHeaderParams.value[item.prop] = data[item.prop];
            searchHeaderParams.value[item.propLike] = data[item.propLike];
            setupContext.root.$nextTick(() => {
                const oldParams = _.cloneDeep(setupContext.refs.searchBox.searchData);
                delete oldParams[item.prop];
                delete oldParams[item.propLike];
                if (item.prop === 'company') {
                    delete oldParams.companyName;
                    delete oldParams.companyId;
                    delete oldParams.accountId;
                    searchHeaderParams.value.companyId = data.companyId;
                }
                setupContext.refs.searchBox.clear();
                setupContext.refs.searchBox.setQueryParams(oldParams);
                subTabTable.getList(searchHeaderParams.value);
            });
        };

        const changeVisible = () => {
            document.getElementById('closeMainVisible').click(); // doClose失效 ,Visible 绑定无法更新到视图 用于关闭其他el-popover弹窗
        };
        const orderBy = ref('');
        const orderByType = ref('');
        // 排序
        const handleSort = ({ prop, order }) => {
            orderBy.value = prop;
            orderByType.value = order === 'ascending' ? 'asc' : 'desc';
            subTabTable.getList(searchHeaderParams.value);
        };
        const errlist = [undefined, null, ''];
        const changeIconActive = (row, prop) => {
            return errlist.includes(row) ? '' : 'icon-active';
        };
            // 检查加载用户是否存在权限新增商机创建
        // 商机批量创建页面加载校验接口（页面打开时调用）
        // 成功0
        // 成功200，弹出提示
        // 失败1，报错
        const resTips = '200';
        // 校验客户
        const isCheckAuth = ref(true);
        const checkList = ref([]);
        const checkCustomerAuth = () => {
            // const customerCodes = selectedList.value.map(it => it.customerCode).join(',');
            const customerClassify = selectedList.value.map(it => it.customerClassify).join(',');
            const customerCodes = _.uniq(selectedList.value.map(it => it.customerCode)).join(',');
            return checkAccount({
                userCode,
                customerCodes,
                customerClassify
            });
        };
            // 判断分公司是否有权限校验
        const getCheckCompanyAuth = () => {
            const params = selectedList.value.map(it => {
                return {
                    userCode,
                    customerId: it.customerId,
                    companyId: it.companyId,
                    fullOpportunitiesId: it.fullOpportunitiesId,
                    window: props.detailDataLocal.window
                };
            });
            return checkCompany({
                list: params
            });
        };
        const checkLoading = ref(false);
        const checkBtnDisabled = ref(false);
        // eslint-disable-next-line complexity
        const getAuthList = async () => {
            if (!selectedList.value.length) {
                Message.warning('请选择数据再进行校验');
                return;
            }
            checkLoading.value = true;
            try {
                const customerRes = await checkCustomerAuth();
                let isCheckAuth = true;
                const companyRes = await getCheckCompanyAuth();
                let customerTips = '';
                let allError = false;
                if (customerRes && (+customerRes.code === 0 || +customerRes.code === +resTips)) {
                    customerRes.data.forEach((it) => {
                    // if (+it.code !== 0 && +it.code !== +resTips) {
                        subTabTable.totalData.value.forEach(el => {
                            if (el.customerCode === it.data.customerCode) {
                                el.customerStatusCode = it.code;
                                if (+it.code === 1) {
                                    customerTips = it.msg;
                                    el.isSelectDisbled = true;
                                    checkList.value.push(el.fullOpportunitiesId);
                                }
                            }
                            return el;
                        });
                    // }
                    });
                    isCheckAuth = customerRes.data.some(it => +it.code === 0 || +it.code === +resTips);
                } else {
                    allError = true;
                }
                // if (customerTips) {
                //     Message.error(customerTips);
                // }
                let tipsText = '';
                if (+companyRes.code === 0) {
                    companyRes.data && companyRes.data.forEach((it, index) => {
                        // if (+it.code ===1) {
                        const currentParam = selectedList.value[index];
                        subTabTable.totalData.value.forEach(el => {
                            if (currentParam.fullOpportunitiesId === el.fullOpportunitiesId) {
                                el.companyStatusCode = it.code;
                                if (+it.code === 1) {
                                    el.isSelectDisbled = true;
                                    tipsText = it.msg;
                                    checkList.value.push(el.fullOpportunitiesId);
                                }
                            }
                            return el;
                        });
                    });
                } else {
                    allError = true;
                }
                if (allError) {
                    subTabTable.totalData.value.forEach(el => {
                        el.isSelectDisbled = true;
                    });
                }
                if (tipsText || customerTips) {
                    Message.error(tipsText + ' ' + customerTips);
                } else {
                    Message.success('校验成功');
                }
                checkLoading.value = false;
                setTableToggle(isCheckAuth);
                selectedList.value = selectedList.value.map(it => {
                    const currentItem = customerRes.data.find(ele => ele.data.customerCode === it.customerCode);
                    if (currentItem) {
                        it.code = currentItem.code;
                    }
                    return it;
                });
                const isCheckCompany = companyRes.data.some(it => +it.code === 0 || +it.code === +resTips);
                setDisAbleBtn(isCheckCompany, isCheckAuth);
            } catch (error) {
                checkLoading.value = false;
                checkBtnDisabled.value = false;
            }
        };
        const setDisAbleBtn = (checkCompany, checkAuth) => {
            if (checkCompany && checkAuth) {
                // 调用通过，置灰【校验】按钮，高亮【确认】按钮
                checkBtnDisabled.value = true;
                isCheckAuth.value = false;
            } else {
                // 调用不通过，标记报错行，保持高亮【校验】按钮，保持置灰【确认】按钮
                checkBtnDisabled.value = false;
                isCheckAuth.value = true;
            }
        };
        const setTableToggle = () => {
            checkList.value.forEach(it => {
                selectedList.value = selectedList.value.filter(item => item.fullOpportunitiesId !== it);
            });
            checkList.value.forEach(it => {
                setupContext.refs.tableBusRef.$refs.elTable.toggleRowSelection(subTabTable.totalData.value.find(ele => ele.fullOpportunitiesId === it), false);
            });
            // isCheckAuth.value = !isCheckAuth;
        };
        const colorList = {
            error: 'error-status-color',
            tips: 'tips-status-color'
        };
        const setRowBgColor = (row) => {
            let bgColor = '';
            if (+row.customerStatusCode === 1 || +row.companyStatusCode === 1) {
                bgColor = colorList.error;
            } else if (+row.companyStatusCode === +resTips) {
                bgColor = colorList.tips;
            }
            return bgColor;
        };
        const selectable = (row, index) => {
            return !row.isSelectDisbled;
        };
        const pageChange = (page) => {
            state.currentPage = page;
            subTabTable.getList();
        };
        const sizeChange = (page) => {
            state.pageSize = page;
            subTabTable.getList();
        };
        const elementChange = ({ column, searchData }) => {
            searchHeaderParams.value[column.value] = '';
            if (column.value === 'companyName') {
                searchHeaderParams.value.company = '';
                searchHeaderParams.value.company = '';
            }
        };
        return {
            ...toRefs(state),
            emit,
            visibleDialog,
            config,
            searchHeaderParams,
            confirm,
            show,
            subTabTable,
            handleSelectionChange,
            visibleSearch,
            handleSort,
            changeIconActive,
            changeVisible,
            getAdvanceValue,
            getheaderParamList,
            getAuthList,
            isCheckAuth,
            setRowBgColor,
            selectable,
            checkLoading,
            pageChange,
            sizeChange,
            elementChange,
            checkBtnDisabled
        };
    }
};
</script>
<style lang="less" scoped>
.icon-active{
    color: #4285f5!important;
}
.price-icon{
    font-size: 12px;
    cursor: pointer;
    color: #000;
}
.bg-color{
    background: red;
}
.status-color{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;

}
.tips-status-color{
   background-color: #f78900 !important
}
.error-status-color{
   background-color: #f44336 !important
}
</style>
<style lang="less">
.abc{
    background: #000!important;
}
.other-change-dialog {
    .chang-tips {
        color: red;
        ul.chang-ul {
            list-style: initial;
            margin-left: 20px;
            li {
                list-style: initial;
            }
            li::marker {
                font-size: 2px; /* 设置圆点大小 */
                margin-right: 4px; /* 设置圆点与文本之间的距离 */
            }
        }
    }
    .change-btn-box {
        display: flex;
        justify-content: center;
        .el-button {
            margin: 10px 20px;
            padding: 0 20px;
        }
    }
}
.bottom-tips{
    font-size: 10px;
    color: #808799;
    margin: 5px 0px 0px 0px;
    line-height: 16px;
}
</style>
