/*eslint-disable*/
import store from '@/store';
import { positiveThirteenAndSix, isNumber } from '@/modules/mdm/utils/regList.js'
const EDIT_TYPE = {
    EDIT: 2,
    CANCEL: -3
};
const tenantCode = store.getters.currentTenant.tenantCode;
const disabledDate = (date) => {
    const now = new Date();
    const nowMonth = now.getMonth();
    const nowYear = now.getFullYear();
    const monthStartData = new Date(nowYear, nowMonth, 1); // 当月第一天
    // const monthEndData = new Date(nowYear, nowMonth + 1, 0); // 当月最后一天
    // return Boolean(date.getTime() > monthEndData.getTime() || date.getTime() < monthStartData.getTime());
    return Boolean(date.getTime() < monthStartData.getTime());
};
// 表单配置信息
export const collapse = [
    {
        title: '基本信息',
        ref: 'basicForm',
        formName: 'basicInfo',
        col: 3,
        labelWidth: '120px',
        visible: true,
        formList: [
            {
                label: '窗口期',
                prop: 'window',
                sourceType: 'time',
                type: 'time',
                timeType: 'date',
                rule: [{ required: true, message: '窗口期不能为空', trigger: 'change' }],
                disabled: false,
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd',
                placeholder: '窗口期',
                pickerOptions: { disabledDate },
            },
            {
                label: '客户名称',
                name: '客户名称',
                prop: 'customerName',
                value: 'customerName',
                type: 'custom',
                config: {
                    label: '客户名称',
                    prop: 'customerName',
                    value: 'customerName',
                    name: '添加客户名称',
                    type: 'advance',
                    advanceConfig: [
                        { prop: 'customerName', value: '客户名称' },
                        { prop: 'customerCode', value: '客户编码' },
                    ],
                    tableConfig: [
                        { prop: 'customerCode', value: '客户编码' },
                        { prop: 'customerName', value: '客户名称' },
                        { prop: 'customerGroupName', value: '客户系' },
                    ],
                    prefix: '/api-lcrm',
                    method: 'post',
                    advanceUrl: '/customer/info/page',
                    cbParams: ['customerCode', 'customerName#customerName', 'crmCustomerId', 'customerClassify', 'customerGroupName'],
                    advanceCode: 'advanceCode',
                    beforeRequest: (params) => {
                        // params.customerStatus = '1';
                        return params;
                    },
                },
                rule: [{ required: true, message: '客户名称不能为空', trigger: 'change' }]
            },
            {
                label: '业务大类',
                prop: 'bigBusinessType',
                value: 'bigBusinessType',
                type: 'select',
                optionsKey: 'SYS_LMDM_BUS_TYPE',
                rule: [{ required: true, message: '业务大类不能为空', trigger: 'change' }]
            },

            {
                label: '运输或存储的货物',
                prop: 'product',
                value: 'product',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                maxlength: 300,
                rows: 4,
                span: 24,
                // optionsKey: 'SYS_WM_CUSTOMER_LEVEL'
                rule: [{ required: true, message: '运输或存储的货物不能为空', trigger: 'input' }]
            },
            {
                label: '客户与货物的货权关系',
                prop: 'propertyRight',
                value: 'propertyRight',
                type: 'select',
                optionsKey: 'NEOCRM_PROPERTY_RIGHT',
                rule: [{ required: true, message: '客户与货物的货权关系不能为空', trigger: 'change' }]
            },
            {
                label: '参标形式',
                prop: 'biddingType',
                value: 'biddingType',
                type: 'select',
                optionsKey: 'NEOCRM_BIDDING_TYPE',
                rule: [{ required: true, message: '参标形式不能为空', trigger: 'change' }]
            },
            {
                label: '是否行业主导',
                prop: 'industryLed',
                value: 'industryLed',
                type: 'select',
                optionsKey: 'NEOCRM_YES_NO',
                rule: [{ required: true, message: '是否行业主导不能为空', trigger: 'change' }]
            },
            {
                label: '商机获取渠道',
                prop: 'opportunitySource',
                value: 'opportunitySource',
                type: 'select',
                optionsKey: 'NEOCRM_OPPORTUNITY_SOURCE',
                rule: [{ required: true, message: '商机获取渠道不能为空', trigger: 'change' }]
            },
            {
                label: '项目名称',
                prop: 'projectName',
                value: 'projectName',
                type: 'input',
                rule: [{ required: true, message: '项目名称不能为空', trigger: 'change' }]
            },
        ]
    },


];
// 表单配置信息
export const oppCollapse = [
    {
        title: '批量新增商机',
        ref: 'dockingForm',
        formName: 'dockingInfo',
        col: 1,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'oppTable',
                value: 'oppTable',
                type: 'custom',
            },

        ]
    },

];
// 客户配置信息
export const customerCollapse = [
    {
        label: '客户简称',
        prop: 'customerShortName',
        value: 'customerShortName',
        type: 'custom',
    },
    {
        label: '客户类型',
        prop: 'customerClassify',
        value: 'customerClassify',
        type: 'custom',
    },
    {
        label: '客户系',
        prop: 'customerGroup',
        value: 'customerGroup',
        type: 'custom',
    },
    {
        label: '客户等级',
        prop: 'customerLevel',
        value: 'customerLevel',
        type: 'custom',
        optionsKey: 'SYS_WM_CUSTOMER_LEVEL',
    },

    {
        label: '是否细分行业',
        prop: 'industryTop',
        value: 'industryTop',
        type: 'custom',
        // optionsKey: 'SYS_WM_CUSTOMER_LEVEL'
    },
    {
        label: '行业',
        prop: 'industry',
        value: 'industry',
        type: 'custom'
    },
    {
        label: '细分行业',
        prop: 'industryType',
        value: 'industryType',
        type: 'custom'
    },
    {
        label: '销售产品',
        prop: 'sellingProducts',
        value: 'sellingProducts',
        type: 'custom'
    },
    {
        label: '风险等级',
        prop: 'riskLevel',
        value: 'riskLevel',
        type: 'custom',
        disabled: true
    },
    {
        label: '不良信用记录',
        prop: 'badRecord',
        value: 'badRecord',
        type: 'custom'
    },
    {
        label: '违约责任与损失',
        prop: 'breachRecord',
        value: 'breachRecord',
        type: 'custom'
    },

]


export const tableConfig = {
    selection: false,
    height: 325,
    columns: [ // 新增合同时编辑表格配置
        {
            label: '状态',
            slot: true,
            prop: 'statusClass'
        },
        {
            label: '客户名称',
            name: '客户名称',
            prop: 'customerName',
            value: 'customerName',
            sourceType: 'advance',
            width: '160',
            type: 'advance',
            fixed: 'left',
            mustFill: true,
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' },
            ],
            tableConfig: [
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerName', value: '客户名称' },
            ],
            prefix: '/api-lcrm',
            method: 'post',
            advanceUrl: '/customer/info/page',
            cbParams: ['customerCode', 'customerName#customerName', 'customerShortName', 'crmCustomerId', 'customerClassify'],
            advanceCode: 'advanceCode',
            beforeRequest: (params) => {
                // params.customerStatus = '1';
                return params;
            },
        },
        {
            label: '本次标的总规模(万)',
            prop: 'bidScale',
            type: 'input',
            mustFill: true,
            verify: positiveThirteenAndSix,
            errorMsg: '只允许输入最大13位正数且最多保留2位小数',
            width: '160'
        },
        {
            label: '分公司',
            prop: 'companyName',
            mustFill: true,
            width: '120'
        },
        {
            name: '经营中心',
            value: 'businessCenter',
            label: '经营中心',
            prop: 'businessCenter',
            sourceType: 'advance',
            type: 'advance',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            advanceConfig: [
                { prop: 'businessCenterName', value: '责任经营中心名称' },
                { prop: 'businessCenterCode', value: '责任经营中心编码' }
            ],
            tableConfig: [
                { prop: 'businessCenterName', value: '责任经营中心名称', 'displayName': 'businessCenterName' },
                { prop: 'businessCenterCode', value: '责任经营中心编码' }
            ],
            mustFill: true,
            prefix: '/api-lcs',
            advanceUrl: '/bid/queryCompanyByCodeAndName',
            advanceCascade: 'companyCode',
            beforeRequest: (params) => {
                params.companyCode = params.companyCode
                return params
            },
            cbParams: ['businessCenterCode', 'businessCenterName#businessCenter'],
            width: '160',
            // advanceCascade: 'companyCode#companyCode' // 关联入参只针对单选有效
        },

        {
            label: '商机名称',
            prop: 'opportunityName',
            type: 'input',
            mustFill: true,
            width: '160'
        },
        {
            label: '窗口期',
            prop: 'window',
            type: 'date',
            width: '160',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            placeholder: '窗口期',
            mustFill: true,
            pickerOptions: { disabledDate },
        },
        {
            label: '商机大类',
            prop: 'businessType',
            type: 'select',
            mustFill: true,
            disabled: true,
            width: 160,
            // optionsKey: 'NEOCRM_BUSINESS_TYPE',
            options: {},
            slot: true,
            content: '全量商机的商机大类有错误时无法带出，请先修正'
        },
        {
            label: '始发仓',
            prop: 'whType',
            type: 'select',
            width: 160,
            optionsParams: 'NEOCRM_WH_TYPE',
            mustFill: true,
            slot: true,
            options: {}
        },
        {
            label: '业务类别',
            prop: 'businessCategory',
            type: 'select',
            width: 160,
            mustFill: true,
            multiple: true,
            slot: true,
            optionsParams: 'SYS_BMS_BUSI_TYPE',
            // optionsKey: 'SYS_BMS_BUSI_TYPE',
            options: {}
        },
        {
            label: '商机类型',
            prop: 'opportunityType',
            type: 'select',
            mustFill: true,
            width: 140,
            optionsKey: 'NEOCRM_OPPORTUNITY_TYPE',
        },
        {
            label: '安得目前业务规模(年/万)',
            prop: 'cooperateScale',
            type: 'input',
            width: 140,
            mustFill: true,
            verify: positiveThirteenAndSix,
            errorMsg: '只允许输入最大13位正数且最多保留2位小数',
        },
        {
            label: '签单计划(年/万)',
            prop: 'money',
            type: 'input',
            width: 140,
            verify: positiveThirteenAndSix,
            errorMsg: '只允许输入最大13位正数且最多保留2位小数',
            mustFill: true,
        },
        {
            label: '承诺签单计划(年/万)',
            prop: 'promiseSigningPlan',
            type: 'input',
            verify: positiveThirteenAndSix,
            errorMsg: '只允许输入最大13位正数且最多保留2位小数',
            width: 140
        },
        {
            label: '现供方',
            prop: 'currentSupplier',
            type: 'input',
            width: 100
        },
        {
            label: '地级市',
            name: '地级市',
            prop: 'prefectureLevelCity',
            value: 'prefectureLevelCity',
            type: 'advance',
            width: 140,
            // type: 'custom',
            visible: false,
            mustFill: true,
            rule: [
            ],
            advanceConfig: [
                { 'prop': 'nameCn', 'value': '城市名称' },
                { 'prop': 'code', 'value': '城市编码' }
            ],
            tableConfig: [
                { 'prop': 'code', 'value': '城市编码' },
                { 'prop': 'nameCn', 'value': '城市名称', displayName: true }
            ],
            prefix: '/api-lcrm',
            method: 'get',
            advanceUrl: `/place/selectPage`,
            cbParams: ['nameCn#prefectureLevelCity'],
            beforeRequest: (params) => {
                params.type = 'PLACE_CITY';
                params.countryCode = 100000;
                return params;
            },
        },
        {
            label: '账期(票后/天)',
            prop: 'accountPeriod',
            type: 'input',
            width: 140,
            mustFill: true,
            verify: isNumber,
            errorMsg: '只允许输入整数',
        },
        {
            label: '投标押金(万)',
            prop: 'bidDeposit',
            type: 'input',
            width: 140,
            mustFill: true,
            verify: positiveThirteenAndSix,
            errorMsg: '只允许输入最大13位正数且最多保留2位小数',
        },
        {
            label: '运作押金(万)',
            prop: 'operateDeposit',
            type: 'input',
            width: 140,
            verify: positiveThirteenAndSix,
            errorMsg: '只允许输入最大13位正数且最多保留2位小数',
        },
        {
            label: '当前推动进展',
            prop: 'latestProgress',
            type: 'textarea',
            width: 300,
            slot: true,
            editSlot: true,
            mustFill: true,
        },
        {
            label: '仓库面积',
            prop: 'whArea',
            type: 'input',
            width: 160,
            mustFill: false,
            verify: isNumber,
            errorMsg: '只允许输入整数',
        },
        {
            label: '仓库要求',
            prop: 'whRequire',
            type: 'textarea',
            width: 300,
            slot: true,
            editSlot: true,
            mustFill: false,
        },
        {
            label: '是否裸租仓',
            prop: 'isPureWarehouse',
            type: 'select',
            width: 120,
            mustFill: false,
            optionsKey: 'NEOCRM_YES_NO',
        },
        {
            label: '日均单量',
            prop: 'dayOrder',
            type: 'input',
            width: 160,
            mustFill: false,
            verify: isNumber,
            errorMsg: '只允许输入整数',
        },
        {
            label: '每单均价',
            prop: 'averagePrice',
            type: 'input',
            width: 160,
            mustFill: false,
            verify: isNumber,
            errorMsg: '只允许输入整数',
        },
        {
            label: '系统对接',
            prop: 'systemDocking',
            type: 'select',
            mustFill: false,
            width: 160,
            optionsKey: 'PMS_SYSTEM_DOCKING',
        },
        {
            label: '是否行业主导',
            prop: 'industryLed',
            type: 'select',
            width: 160,
            mustFill: true,
            optionsKey: 'NEOCRM_YES_NO',
        },
        {
            label: '参标形式',
            prop: 'biddingType',
            type: 'select',
            width: 160,
            mustFill: true,
            optionsKey: 'NEOCRM_BIDDING_TYPE',
        },
        {
            label: '商机获取渠道',
            prop: 'opportunitySource',
            width: 160,
            type: 'select',
            mustFill: true,
            optionsKey: 'NEOCRM_OPPORTUNITY_SOURCE',
        },
        {
            label: '关联客户业务规划',
            prop: 'fullOpportunitiesCode',
            width: 160,
            mustFill: true,
        },
        // {
        //     label: '服务平台',
        //     type: 'slot',
        //     prop: 'site',
        //     width: 450,
        //     value: 'site',
        //     slot: true,
        //     editSlot: true,
        //     mustFill: false,
        //     disabled: false,
        //     placeholder: '',
        //     // siteAdvanceParams: {
        //     name: '服务平台',
        //     advanceLabel: '添加服务平台',
        //     advanceConfig: [
        //         { 'prop': 'siteNameCn', 'value': '服务平台名称' },
        //         { 'prop': 'siteCode', 'value': '服务平台编码' }
        //     ],
        //     tableConfig: [
        //         { 'prop': 'siteCode', 'value': '服务平台编码' },
        //         { 'displayName': 'siteNameCn', 'prop': 'siteNameCn', 'value': '服务平台名称' }
        //     ],
        //     prefix: '/api-mdm',
        //     multiple: true, // 多选
        //     advanceUrl: `/esCompany/popSite`,
        //     cbParams: ['siteCode', 'siteNameCn#siteName'],
        //     advanceCascade: 'companyCode#companyCode',
        //     hideTooltip: true
        //     // }
        // },

    ],
    actions: { // 表格操作列的配置
        fixedWidth: 160, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px

        list: [
            // {
            //     label: '编辑',
            //     event: 'edits',
            //     displayRule: (row) => {
            //         return +row.edit !== EDIT_TYPE.EDIT
            //     }
            // },
            {
                label: '保存',
                event: 'saveItem',
                displayRule: (row) => {
                    return +row.edit === EDIT_TYPE.EDIT
                }
            },
            {
                label: '复制',
                event: 'copy',
                displayRule: (row) => {
                    return +row.edit !== EDIT_TYPE.EDIT
                }
            }, {
                label: '删除',
                event: 'del',
                displayRule: (row) => {
                    return +row.edit !== EDIT_TYPE.EDIT
                }
            }]
    },
};

export const neocrmBusinessRelation = {
    1: [1, 2], // 仓储  =>仓储,装卸
    2: [1, 2], // 装卸	  =>仓储,装卸
    22: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 21, 22], // 报关	  => 仓储,装卸,报关,运输,配送,其他业务,资产转卖,服务,机器人,宅配,设备销售,安装,供应链管理,设备租赁,油品销售,绿色回收,燃料销售,跨境电商
    3: [3, 4, 6, 8, 10, 11, 13, 14], // 运输  => 供应链管理、设备销售、设备租赁、配送、运输、其他业务、服务、宅配
    4: [3, 4, 6, 8, 10, 11, 13, 14], // 配送	  => 运输,配送,其他业务,服务,宅配,设备销售,供应链管理,设备租赁
    5: [5], // 快递	  => 快递
    6: [3, 4, 6, 8, 10, 11, 13, 14], // 其他业务	  => 运输,配送,其他业务,服务,宅配,设备销售,供应链管理,设备租赁
    7: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 21, 22], // 资产转卖  =>仓储,装卸,报关,运输,配送,其他业务,资产转卖,服务,机器人,宅配,设备销售,安装,供应链管理,设备租赁,油品销售,绿色回收,燃料销售,跨境电商
    8: [3, 4, 6, 8, 10, 11, 13, 14], // 服务	  => 运输,配送,其他业务,服务,宅配,设备销售,供应链管理,设备租赁
    9: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 21, 22], // 机器人	  =>仓储,装卸,报关,运输,配送,其他业务,资产转卖,服务,机器人,宅配,设备销售,安装,供应链管理,设备租赁,油品销售,绿色回收,燃料销售,跨境电商
    10: [3, 4, 6, 8, 10, 11, 13, 14], // 宅配	  => 	运输,配送,其他业务,服务,宅配,设备销售,供应链管理,设备租赁
    11: [3, 4, 6, 8, 10, 11, 13, 14], // 设备销售	  =>运输,配送,其他业务,服务,宅配,设备销售,供应链管理,设备租赁
    12: [12], // 安装	  => 安装
    13: [3, 4, 6, 8, 10, 11, 13, 14], // 供应链管理	  => 运输,配送,其他业务,服务,宅配,设备销售,供应链管理,设备租赁
    14: [3, 4, 6, 8, 10, 11, 13, 14], // 设备租赁	  => 运输,配送,其他业务,服务,宅配,设备销售,供应链管理,设备租赁
    15: [15, 16], // 油品销售	  => 油品销售,绿色回收
    16: [15, 16], // 绿色回收	  => 油品销售,绿色回收
    17: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 21, 22], // 燃料销售	  =>仓储,装卸,报关,运输,配送,其他业务,资产转卖,服务,机器人,宅配,设备销售,安装,供应链管理,设备租赁,油品销售,绿色回收,燃料销售,跨境电商
    21: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 21, 22], // 跨境电商  =>仓储,装卸,报关,运输,配送,其他业务,资产转卖,服务,机器人,宅配,设备销售,安装,供应链管理,设备租赁,油品销售,绿色回收,燃料销售,跨境电商


}
